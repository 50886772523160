<template>
    <div class="col-md-10 col-md-offset-1 col-lg-10 col-lg-offset-1 col-sm-12 col-xs-12">
        <div class="col-lg-6 col-md-6 col-sm-12 col-xs-12 mb-3">
            <!--            <div class="review-detail-form">-->
            <!--                <div class="row review-detail-row important-text">-->
            <!--                    <div class="col-md-6 col-xs-12 col-sm-6 col-lg-6 review-detail-header" >-->
            <!--                        You send-->
            <!--                    </div>-->
            <!--                    <div class="col-md-6 col-xs-12 col-sm-6 col-lg-6 review-detail-value" >-->
            <!--                        USD 100-->
            <!--                    </div>-->
            <!--                </div>-->
            <!--                <div class="row review-detail-row ">-->
            <!--                    <div class="col-md-6 col-xs-12 col-sm-6 col-lg-6 review-detail-header extra-header-container">-->
            <!--                        Exchange Rate (BTC-USD)-->
            <!--                        <small class="extra-header-text">(guaranteed for xx hours/indicative)</small>-->
            <!--                    </div>-->
            <!--                    <div class="col-md-6 col-xs-12 col-sm-6 col-lg-6 review-detail-value" >-->
            <!--                        34,027.86-->
            <!--                    </div>-->
            <!--                </div>-->
            <!--                <div class="row review-detail-row">-->
            <!--                    <div class="col-md-6 col-xs-12 col-sm-6 col-lg-6 review-detail-header" >-->
            <!--                        Crypto Amount Paid-->
            <!--                    </div>-->
            <!--                    <div class="col-md-6 col-xs-12 col-sm-6 col-lg-6 review-detail-value" >-->
            <!--                        BTC 0.00292407-->
            <!--                    </div>-->
            <!--                </div>-->
            <!--                <div class="row review-detail-row">-->
            <!--                    <div class="col-md-6 col-xs-12 col-sm-6 col-lg-6 review-detail-header" >-->
            <!--                        Total fees-->
            <!--                    </div>-->
            <!--                    <div class="col-md-6 col-xs-12 col-sm-6 col-lg-6 review-detail-value" >-->
            <!--                        USD 0.5-->
            <!--                    </div>-->
            <!--                </div>-->
            <!--                <div class="row review-detail-row">-->
            <!--                    <div class="col-md-6 col-xs-12 col-sm-6 col-lg-6 review-detail-header" >-->
            <!--                        Amount we'll convert-->
            <!--                    </div>-->
            <!--                    <div class="col-md-6 col-xs-12 col-sm-6 col-lg-6 review-detail-value" >-->
            <!--                        USD 99.50-->
            <!--                    </div>-->
            <!--                </div>-->

            <!--                <div class="row review-detail-row important-text">-->
            <!--                    <div class="col-md-6 col-xs-12 col-sm-6 col-lg-6 review-detail-header" >-->
            <!--                        Recipient gets-->
            <!--                    </div>-->
            <!--                    <div class="col-md-6 col-xs-12 col-sm-6 col-lg-6 review-detail-value" >-->
            <!--                        EUR 80.59-->
            <!--                    </div>-->
            <!--                </div>-->
            <!--                <div class="row review-detail-row">-->
            <!--                    <div class="col-md-6 col-xs-12 col-sm-6 col-lg-6 review-detail-header" >-->
            <!--                        Exchange Rate (USD - EUR)-->
            <!--                    </div>-->
            <!--                    <div class="col-md-6 col-xs-12 col-sm-6 col-lg-6 review-detail-value" >-->
            <!--                        0.81-->
            <!--                    </div>-->
            <!--                </div>-->
            <!--                <hr>-->
            <!--                <p class="form-title">Recipient Details</p>-->
            <!--                <div class="row review-detail-row">-->
            <!--                    <div class="col-md-6 col-xs-12 col-sm-6 col-lg-6 review-detail-header" >-->
            <!--                        Name-->
            <!--                    </div>-->
            <!--                    <div class="col-md-6 col-xs-12 col-sm-6 col-lg-6 review-detail-value" >-->
            <!--                        John Smith-->
            <!--                    </div>-->
            <!--                </div>-->
            <!--                <div class="row review-detail-row">-->
            <!--                    <div class="col-md-6 col-xs-12 col-sm-6 col-lg-6 review-detail-header" >-->
            <!--                        Email-->
            <!--                    </div>-->
            <!--                    <div class="col-md-6 col-xs-12 col-sm-6 col-lg-6 review-detail-value" >-->
            <!--                        johnsmith@gmail.com-->
            <!--                    </div>-->
            <!--                </div>-->
            <!--                <div class="row review-detail-row">-->
            <!--                    <div class="col-md-6 col-xs-12 col-sm-6 col-lg-6 review-detail-header" >-->
            <!--                        Bank Account Number-->
            <!--                    </div>-->
            <!--                    <div class="col-md-6 col-xs-12 col-sm-6 col-lg-6 review-detail-value" >-->
            <!--                        1234567890-->
            <!--                    </div>-->
            <!--                </div>-->
            <!--                <div class="row review-detail-row">-->
            <!--                    <div class="col-md-6 col-xs-12 col-sm-6 col-lg-6 review-detail-header" >-->
            <!--                        BIC/Swift Code-->
            <!--                    </div>-->
            <!--                    <div class="col-md-6 col-xs-12 col-sm-6 col-lg-6 review-detail-value" >-->
            <!--                        DBSSGSG-->
            <!--                    </div>-->
            <!--                </div>-->

            <!--            </div>-->
            <ReviewDetails/>
        </div>
        <div class="col-lg-6 col-md-6 col-sm-12 col-xs-12">
            <div class="row">
                <!--                <div class="kyc-container" v-if="!showKYC">-->
                <!--                    <h3 class="text-center mt-2 mb-3">Identity Verification</h3>-->
                <!--                    <div class="row iframe-container">-->
                <!--                        Onfido KYC here-->
                <!--                    </div>-->
                <!--                    <div class="row">-->
                <!--                        <button class="form-btn text-center mt-3" @click="submitKYCDetails()">Continue</button>-->
                <!--                    </div>-->
                <!--                </div>-->
                <!--                <div class="row">-->
                <!--                    <div class="kyc-container">-->
                <!--                        <h3 class="text-center mt-2 mb-3">Identity Verification</h3>-->
                <!--                        <br>-->
                <!--                        <br>-->
                <!--                        <p class="text-center mt-3">We only require for your identity document when :</p>-->
                <!--                        <ul class="text-center">-->
                <!--                            <li>-->
                <!--                                - the transfer amount is above SGD 1,500.00 equivalent-->
                <!--                            </li>-->
                <!--                            <li>-->
                <!--                                - aggregated monthly transfer amount is above SGD 15,000.00 equivalent.-->
                <!--                            </li>-->
                <!--                        </ul>-->
                <!--                        <p>Please proceed to the next step to make payment</p>-->
                <!--                        <div class="row">-->
                <!--                            <button class="form-btn text-center mt-3" @click="submitKYCDetails()">Continue</button>-->
                <!--                        </div>-->


                <!--                    </div>-->
                <!--                </div>-->

                <div class="row">
                    <div class="kyc-container">
                        <h3 class="text-center mt-2 mb-3">Identity Verification</h3>
                        <br>
                        <br>
                        <p class="text-center mt-3">The transaction amount has reached a certain threshold for identity
                            verification.</p>
                        <p>You will be redirect to verification url in {{countdownTimer}} seconds...</p>

                        <p>
                            or click the link below if you are not redirect
                        </p>

                        <div class="row">
                                <a class="m-5" :href="kycUrl">{{kycUrl}}</a>
                        </div>


                    </div>
                </div>
            </div>


        </div>
    </div>
</template>
<script>
    import ReviewDetails from './ReviewDetailsComponent'
    import {mapGetters} from 'vuex'

    export default {
        name: 'kyc-details',
        components: {
            ReviewDetails
        },
        data() {
            return {
                remittanceInfoProps: null,
                showKYC: true,

                kycUrl: "https://web-idv.app.authenteq.com/verify?token=c6fff545-e9c4-4d53-ae59-710d59aed09f",
                timeout: 5000,
                countdownTimer : 5,
            }
        },
        watch: {
            $route() {

            }
        },
        computed: {
            ...mapGetters(["paymentDetailsGetter"])
        },
        mounted() {
            console.debug("KYC details Mounted");

            //get data from vuexstate
            this.remittanceInfoProps = this.paymentDetailsGetter && {...this.paymentDetailsGetter.remittanceInfo};
            console.log("this.remittanceInfoProps", this.remittanceInfoProps);

            //check if params consist of tx reference
            let url = new URL(window.location);
            let txReferenceFromParams = url.searchParams.get('txReference')
            console.log("Tx Reference Params", txReferenceFromParams);

            //check from API call whether KYC true or not.
            //if true, needed KYC text shows.

            // this.kycUrl = this.paymentDetailsGetter.remittanceInfo && this.paymentDetailsGetter.remittanceInfo.kycUrl;

            //check if kyc status is new
            //if new, setTimeout to redirect them to the url after 5 seconds

            this.redirectUrl(this.kycUrl, this.timeout);
            this.timerInterval(this.countdownTimer);



            //if passed or failed show the necessary screen


            //if not, show the text we needed KYC only when the threshold is reach.
            // this.showKYC = true;




        },
        methods: {
            submitKYCDetails() {
                this.$router.push('/transfer/make-payment');
            },

            redirectUrl(url,timeout)
            {
                window.setTimeout(function () {
                    //change to kyc url
                    window.top.location.href = url;
                }, timeout)
            },

            timerInterval(countdownTimer)
            {
                var vm = this;
                let timer = setInterval(function () {
                    if (countdownTimer <= 0) {
                        clearInterval(timer);
                    }
                    countdownTimer -= 1;
                    vm.countdownTimer = countdownTimer;
                }, 1000);
            },
        }
    }
</script>