<template>
            <div class="col-xs-12 col-sm-offset-1 col-sm-10 col-md-offset-2 col-md-10 col-lg-offset-2 col-lg-10">
                <h3 class="mb-3">
                    Transfer to {{recipientName}}
                </h3>
                <div class="review-detail-form">
                    <div class="row">
                        <div class="col-md-12 col-xs-12 col-sm-12 col-lg-12">
                            <p class="review-element review-element-large mb-1">
                                <small class="review-element-name">
                                    Recipient gets exactly
                                </small>
                                <span class="review-element-value review-element-h3 h3">
                                    {{formatFiatPrice(receiveAmount)}} {{receiveCurrency}}
                                </span>
                            </p>
                            <p class="review-element review-element-large mb-1">
                                <small class="review-element-name">
                                    Crypto amount to pay
                                </small>
                                <span class="review-element-value review-element-h3 h3">
                                    {{cryptoAmountToPay}}
                                </span>
                            </p>
                            <p class="review-element" style="margin-top:-12px">
                                <small class="review-element-name" style="font-size: 13px">
                                    including fees of
                                    <!--<br>
                                    <small>Guaranteed for xx hours/indicative</small>-->
                                </small>
                                <span class="review-element-value text-primary" style="font-size: 13px">
                                    {{formatFiatPrice(transferFee)}} {{receiveCurrency}}
                                </span>
                            </p>
                            <p class="review-element mb-1" style="margin-top:-10px">
                                <small class="review-element-name">
                                    Exchange rate
                                    <!--<br>
                                    <small>Guaranteed for xx hours/indicative</small>-->
                                </small>
                                <span class="review-element-value text-primary">
                                    1 {{exchangeRateValueFormat}}
                                </span>
                            </p>
                            <div class="pt-1 pb-2">
                                <hr>
                            </div>
                            <div class="flex-col-display mb-2">
                                <span>Upon successful payment, TransCrypt will transfer the fund to the recipient's bank within <strong>one business day</strong>.</span>
                            </div>

<!--                            <h5 class="mb-2">-->
<!--                                Recipient details-->
<!--                            </h5>-->

<!--                            <p class="review-element mb-1">-->
<!--                                <small class="review-element-name">-->
<!--                                    Name-->
<!--                                </small>-->
<!--                                <span class="review-element-value text-primary">-->
<!--                                    {{recipientName}}-->
<!--                                </span>-->
<!--                            </p>-->

<!--                            <p class="review-element mb-1">-->
<!--                                <small class="review-element-name">-->
<!--                                    Email-->
<!--                                </small>-->
<!--                                <span class="review-element-value text-primary">-->
<!--                                    {{recipientEmail}}-->
<!--                                </span>-->
<!--                            </p>-->

<!--                            <p class="review-element mb-1">-->
<!--                                <small class="review-element-name">-->
<!--                                    Bank account number-->
<!--                                </small>-->
<!--                                <span class="review-element-value text-primary">-->
<!--                                    {{recipientBankAccountNumber}}-->
<!--                                </span>-->
<!--                            </p>-->

<!--                            <p class="review-element mb-1">-->
<!--                                <small class="review-element-name">-->
<!--                                    BIC/SWIFT code-->
<!--                                </small>-->
<!--                                <span class="review-element-value text-primary">-->
<!--                                    {{recipientSWIFTCode}}-->
<!--                                </span>-->
<!--                            </p>-->
                        </div>
                    </div>


                </div>
            </div>


</template>
<script>
    import {mapActions, mapGetters} from "vuex";

    export default {
    name: 'review-details-table',
    props: {
        remittanceInfo: Object,
    },
        data() {
            return {
                sendAmount: "",
                sendCurrency: "",
                cryptoAmount: "",
                transferFee: "",
                convertedAmount: "",
                receiveAmount: "",
                receiveCurrency: "",
                cryptoExchangeRate: "",
                fiatExchangeRate: "",
                cryptoCurrency : "",


                inPaymentScreen : false,


                //recipient details
                recipientName: "",
                recipientEmail: "",
                recipientBankAccountNumber: "",
                recipientSWIFTCode: "",

                debugConsole : false,
            }
        },
        watch: {
            $route() {

            }
        },
        computed: {
            ...mapGetters(["paymentDetailsGetter", "userDetailsGetter"]),
            cryptoAmountToPay() {
                let amountAndCcy = '';
                if(this.cryptoCurrency === "LNBC") {
                    amountAndCcy = this.formatFiatPrice(this.cryptoAmount * 1e8) + ' SATS';
                } else {
                    amountAndCcy = ` ${this.cryptoAmount} ${this.cryptoCurrency}`;
                }
                return amountAndCcy;
            },
            exchangeRateValueFormat() {
                let exchangeStr = "";
                if(this.cryptoCurrency === "LNBC") {
                    exchangeStr = ` SATS = ${this.cryptoExchangeRate} ${this.sendCurrency}`
                } else {
                    exchangeStr = ` ${this.cryptoCurrency} = ${this.formatFiatPrice(this.cryptoExchangeRate)} ${this.sendCurrency}`
                }
                return exchangeStr;
            }
        },
        mounted() {
            if (this.debugConsole) console.debug("Review Details Mounted");
            //
            // let url = new URL (window.location);
            // console.log("Url Location",url)
            //
            //
            // this.inPaymentScreen = url.href.indexOf('/transfer/make-payment') > 0 ? true : false;
            //
            // console.log("Is make payment screen", url.href.indexOf('/transfer/make-payment') > 0)
            //
            // if(this.inPaymentScreen)
            // {
            //     console.log("In make payment screen");
            //     let remittanceInfo = this.paymentDetailsGetter.paymentRequestInfo;
            //     let recipientInfo = this.paymentDetailsGetter.recipientInfo;
            //
            //     this.sendAmount = remittanceInfo.sendAmount;
            //     this.sendCurrency = remittanceInfo.sendCurrency;
            //     this.cryptoAmount = remittanceInfo.cryptoAmount;
            //     this.cryptoExchangeRate = remittanceInfo.cryptoExchangeRate;
            //     this.fiatExchangeRate = remittanceInfo.fiatExchangeRate;
            //     this.transferFee = remittanceInfo.transferFee;
            //     this.convertedAmount = remittanceInfo.finalSendAmount;
            //     this.receiveAmount = remittanceInfo.receiveAmount;
            //     this.receiveCurrency = remittanceInfo.receiveCurrency;
            //
            //     this.recipientName = recipientInfo.recipientFullName;
            //     this.recipientEmail = recipientInfo.recipientEmail;
            //     this.recipientBankAccountNumber = recipientInfo.recipientBankAccountNumber;
            //     this.recipientSWIFTCode = recipientInfo.recipientBankSwiftCode;
            //
            //
            // }
            // else
            // {
            //     let remittanceInfo = this.paymentDetailsGetter.remittanceInfo;
            //     let recipientInfo = this.paymentDetailsGetter.recipientInfo;
            //
            //     this.sendAmount = remittanceInfo.sendAmount;
            //     this.sendCurrency = remittanceInfo.sendCurrency;
            //     this.cryptoAmount = remittanceInfo.cryptoAmount;
            //     this.cryptoExchangeRate = remittanceInfo.cryptoExchangeRate;
            //     this.fiatExchangeRate = remittanceInfo.fiatExchangeRate;
            //     this.transferFee = remittanceInfo.transferFee;
            //     this.convertedAmount = remittanceInfo.finalSendAmount;
            //     this.receiveAmount = remittanceInfo.receiveAmount;
            //     this.receiveCurrency = remittanceInfo.receiveCurrency;
            //
            //     this.recipientName = recipientInfo.recipientFullName;
            //     this.recipientEmail = recipientInfo.recipientEmail;
            //     this.recipientBankAccountNumber = recipientInfo.recipientBankAccountNumber;
            //     this.recipientSWIFTCode = recipientInfo.recipientBankSwiftCode;
            // }


            let remittanceInfo = this.paymentDetailsGetter.remittanceInfo;
            let recipientInfo = this.paymentDetailsGetter.recipientInfo;
            // let userDetails = this.userDetailsGetter;


            this.sendCurrency = remittanceInfo.sendCurrency || "";
            this.cryptoExchangeRate = remittanceInfo.cryptoExchangeRate || 0;
            this.transferFee = remittanceInfo.transferFee || 0;
            this.receiveAmount = remittanceInfo.receiveAmount || "";
            this.receiveCurrency = remittanceInfo.receiveCurrency || "";
            this.cryptoCurrency = remittanceInfo.cryptoCurrency || "";
            // this.cryptoAmount = this.cryptoCurrency !== 'USDT' ? this.formatCryptoPrice(remittanceInfo.cryptoAmount) : this.formatFiatPrice(remittanceInfo.cryptoAmount) || 0;
            this.cryptoAmount = remittanceInfo.cryptoAmount || 0;

            this.recipientName = recipientInfo.recipientFullName || "";
            this.recipientEmail = recipientInfo.recipientEmail || "";

            this.recipientBankAccountNumber = recipientInfo.recipientBankAccountNumber || "";
            this.recipientSWIFTCode = recipientInfo.recipientBankSwiftCode || "";


        },

        methods: {

        }

}

</script>
<style>

</style>
